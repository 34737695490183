import { createContext, useState } from "react"
import useMessages from "./useMessages"

export const MessagesContext = createContext()

const MessagesContextProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(1)
    const {
        messages: updateMessages,
        loading: updateMessagesLoading,
        setNeedRefetch: setNeedRefetchUpdateMessages,
        pageCount
    } = useMessages(currentPage, setCurrentPage)

    const value = {
        updateMessages,
        updateMessagesLoading,
        setNeedRefetchUpdateMessages,
        pageCount,
        currentPage,
        setCurrentPage,
    }

    return (
        <MessagesContext.Provider value={value}>
            {children}
        </MessagesContext.Provider>
    )
}

export default MessagesContextProvider
