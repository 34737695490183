import useInvites from "../../../context/useInvites"
import dayjs from "dayjs"
import axios from "axios"
import { API_URL, messageToToasts } from "../../../utils"
import LoadingSpinner from "../../../helpers/LoadingSpinner"
import { useTranslation } from "react-i18next"

const InviteList = ({ userType, isActive }) => {
  const { loading, invites, setNeedRefetch } = useInvites(userType, isActive)
  const { t } = useTranslation()

  const deleteInvite = async inviteId => {
    const data = {
      id: inviteId,
    }
    const resp = await axios.post(`${API_URL}/invite/deleteOne`, data)

    const { status, message } = resp.data

    setNeedRefetch(true)

    if (status === "failed") {
      return messageToToasts(message)
    }
  }

  const invitesTable = (
    <div className="box">
      {invites.length ? (
        <div className="table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>{t("auth.email")}</th>
                <th>{t("invite.created")}</th>
                {isActive ? <th>{t("invite.expired")}</th> : <></>}
              </tr>
            </thead>
            <tbody>
              {invites.map(invite => (
                <tr key={invite.id}>
                  <th>{invite.email}</th>
                  <td>
                    {dayjs(invite.creationDate).format("DD/MM/YYYY - HH:mm")}
                  </td>

                  {isActive ? (
                    <td>
                      {dayjs(invite.expirationDate).format(
                        "DD/MM/YYYY - HH:mm"
                      )}
                    </td>
                  ) : (
                    <></>
                  )}

                  {invite.isActive ? (
                    <td>
                      <button
                        className="delete is-medium"
                        onClick={() => deleteInvite(invite.id)}
                      ></button>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="has-text-centered has-text-grey"> {t("listEmpty")} </p>
      )}
    </div>
  )

  return loading ? <LoadingSpinner /> : invitesTable
}

export default InviteList
