import i18n from "./i18n"

const ERRORS = {
  ru: {
    "sw:::000":
      "Ошибка сервера. Перезагрузите страницу или обратитесь к администратору",
    "sw:::001": "Ошибка базы данных",
    "sw:::002": "Неправильный формат запроса",
    "sw:::003": "Недостаточно прав для данного действия",
    "sw:::004": "Ошибка почтового клиента",
    "sw:::005": "Ошибка обработки файла",

    "auth:::000": "Неверный email",
    "auth:::001": "Пароль и повтор пароля не совпадают",
    "auth:::002": "Пользователь с таким логином не найден",
    "auth:::003": "Пароль неверен",
    "auth:::004": "Приглашение для такого email уже существует",
    "auth:::005":
      "Пользователь с таким именем и типом уже существует. Пожалуйста, обратитесь к администратору",
    "auth:::006":
      "Ваш инвайт не найден. Пожалуйста, обратитесь к админимтратору",
    "auth:::007": "Доступ запрещён",
    "auth:::008": "Ссылка смены пароля уже была использована либо устарела",
    "auth:::009": "Введите email",
    "auth:::010": "Введите пароль",
    "auth:::011": "Email не является валидным",

    "sc:::000": "Сценарий не найден. Обратитесь к администратору",
    "sc:::001": "Доступ запрещён. Обратитесь к администратору",
    "sc:::002": "Название сценария должно быть непустым",
    "sc:::003": "Загрузите xlsx-файл",
    "sc:::004": "ID сценария для сравнения некорректен",
    "sc:::005": "Сценарий сравнения не найден",
    "sc:::006": "Сценарий сравнения должен иметь статус 'finished'",

    "su:::000": "Введите пароль",
    "su:::001": "Введите повтор пароля",
    "su:::002": "Слишком простой пароль",
    "su:::003": "Пароли не совпадают",
    "su:::004": "Введите имя",
    "su:::005": "Введите фамилию",
  },
  en: {
    "sw:::000": "Server error. Try to reload page or contact administrator",
    "sw:::001": "Database error",
    "sw:::002": "Invalid request fromat",
    "sw:::003": "Access denied",
    "sw:::004": "Mail client error",
    "sw:::005": "file processing error",

    "auth:::000": "Incorrect email",
    "auth:::001": "Password and password repeat are not equal",
    "auth:::002": "No user with such login",
    "auth:::003": "Password is incorrect",
    "auth:::004": "Invite for such email is already exists",
    "auth:::005":
      "User with such email and user type is already exists. Please, contact system administrator",
    "auth:::006":
      "No invite or more than one invite for that code. Please, check login type and contact administrator",
    "auth:::007": "Access denied",
    "auth:::008": "Link is outdated or has already used",
    "auth:::009": "Please, insert email",
    "auth:::010": "Please, insert password",
    "auth:::011": "Invalid email",

    "sc:::000": "Scenario was not found. Please, contact administrator",
    "sc:::001": "Access denied. Please, contact administrator",
    "sc:::002": "Scenario name must be non-empty",
    "sc:::003": "Please, upload xlsx file",
    "sc:::004": "Inserted comparison ID is invalid",
    "sc:::005": "Comparison scenario was not found",
    "sc:::006": "Comparison scenario must have status 'finished'",

    "su:::000": "Please, insert password",
    "su:::001": "Please, insert password repeat",
    "su:::002": "Password is not strong enough",
    "su:::003": "Passwords are not equal",
    "su:::004": "Please, insert first name",
    "su:::005": "Please, insert last name",
  },
}

export const errorMessage = code => ERRORS[i18n.language][code]
