import UserList from "./UserList"
import { useState } from "react"
import { userTypesOptions } from "../../../utils"

import Selector from "../../../helpers/Selector"

const UserListView = () => {
  const [userType, setUserType] = useState("ordinary")

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-12">
          <div className="columns is-centered">
            <div className="column is-4">
              <Selector
                options={userTypesOptions}
                defaultValue={userTypesOptions[0]}
                onChange={target => setUserType(target.value)}
              />
            </div>
          </div>
          <UserList userType={userType} />
        </div>
      </div>
    </>
  )
}

export default UserListView
