import { Route, Switch, Redirect } from "react-router-dom"
import Auth from "./auth/Auth"
import { useContext } from "react"
import { UserContext } from "./context/UserContext"
import CommonWorkspace from "./workspace/CommonWorkspace"
import ResetPassword from "./auth/ResetPassword"
import Footer from "./helpers/Footer"

function App() {
  const { isSigned } = useContext(UserContext)

  return (
    <>
      <div id="wrapper">
        <Switch>
          <Route exact path="/">
            <Redirect to="/common" />
          </Route>

          <Route path="/auth">
            {isSigned ? <Redirect to="/common" /> : <Auth />}
          </Route>

          <Route path={"/resetPassword"}>
            <ResetPassword />
          </Route>

          <Route path="/common">
            {isSigned ? <CommonWorkspace /> : <Redirect to="/auth" />}
          </Route>
        </Switch>
      </div>

      <Footer />
    </>
  )
}

export default App
