import axios from "axios"
import { useEffect, useState } from "react"
import { API_URL, messageToToasts } from "../utils"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"

const useInvites = (userType, isActive) => {
  const [invites, setInvites] = useState([])
  const [loading, setLoading] = useState(true)
  const [needRefetch, setNeedRefetch] = useState(true)

  const fetchInvites = async () => {
    try {
      const resp = await axios.get(
        `${API_URL}/invite/list?userType=${userType}&isActive=${isActive}`
      )

      const { status, message, invites } = resp.data

      if (status === "failed") {
        return messageToToasts(message)
      }

      setInvites(invites)
      setLoading(false)
    } catch (error) {
      toast.error(errorMessage("sw:::000"))
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchInvites()
    // eslint-disable-next-line
  }, [userType, isActive])

  useEffect(() => {
    if (needRefetch) {
      setLoading(true)
      fetchInvites()
      setNeedRefetch(false)
    }
    // eslint-disable-next-line
  }, [needRefetch])

  return { loading, invites, setNeedRefetch }
}

export default useInvites
