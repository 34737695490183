import { useTranslation } from "react-i18next"

const LangAuthSelector = () => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <span className="has-text-grey">{t("appChangeLng")}</span>:&nbsp;
      <span
        className={"pointer" + (i18n.language !== "ru" ? " has-text-link" : "")}
        onClick={() => i18n.changeLanguage("ru")}
      >
        ru
      </span>
      &nbsp;
      <span
        className={"pointer" + (i18n.language !== "en" ? " has-text-link" : "")}
        onClick={() => i18n.changeLanguage("en")}
      >
        en
      </span>
    </>
  )
}

export default LangAuthSelector
