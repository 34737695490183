import axios from "axios"
import { useEffect, useState } from "react"
import { API_URL, messageToToasts } from "../utils"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"

const useUpdateMessages = (currentPage, setCurrentPage) => {

    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(true)
    const [needRefetch, setNeedRefetch] = useState(true)

    const [pageCount, setPageCount] = useState(0)


    const fetchUpdateMessages = async () => {

        setLoading(true)
        try {
            const resp = await axios.get(
                `${API_URL}/messages/update/list?page=${currentPage}`
            )

            const {
                status,
                message,
                messages,
                pageCount,
            } = resp.data

            if (status === "failed") {
                return messageToToasts(message)
            }


            setPageCount(pageCount)
            setMessages(messages)
            setLoading(false)
        } catch (error) {
            toast.error(errorMessage("sw:::000"))
        }
    }

    useEffect(() => {
        if (needRefetch) {
            setCurrentPage(1)
            fetchUpdateMessages()
            setNeedRefetch(false)
        }
        // eslint-disable-next-line
    }, [needRefetch])

    useEffect(() => {
        fetchUpdateMessages()
        setNeedRefetch(false)
        // eslint-disable-next-line
    }, [currentPage])

    return { loading, messages, setNeedRefetch, pageCount }
}

export default useUpdateMessages
