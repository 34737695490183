import { useTranslation } from "react-i18next"
import UpdateMessageCreate from "./UpdateMessageCreate"
import UpdateMessageList from "./UpdateMessageList"
import { useContext } from "react"
import { MessagesContext } from "../../../context/MessagesContext"
import LoadingSpinner from "../../../helpers/LoadingSpinner"
import { UserContext } from "../../../context/UserContext"

const MainView = () => {
  const { t } = useTranslation()
  const { loading: updateMessagesLoading } = useContext(MessagesContext)

  const {
    user: { userType }
  } = useContext(UserContext)

  return (
    <>


      {userType === "admin" ? <UpdateMessageCreate /> : null}



      <div className="box">
        <div className="title is-4 has-text-centered">{t("main.video")}</div>
        <div className="has-text-centered">
          <iframe
            width="600"
            height="315"
            src="https://www.youtube.com/embed/fRD8XBY1jWk"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
          />
        </div>


        {updateMessagesLoading
          ? <LoadingSpinner />
          : <UpdateMessageList />
        }



      </div>
    </>
  )
}

export default MainView
