import axios from "axios"
import { useState } from "react"
import { API_URL, isEmpty, messageToToasts, validateEmail } from "../utils"
import LangAuthSelector from "./LangAuthSelector"
import { useTranslation } from "react-i18next"
import { errorMessage } from "../errors"
import { toast } from "react-toastify"

const ApplyReset = ({ userType }) => {
  const [email, setEmail] = useState("")
  const [redEmail, setRedEmail] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const { t } = useTranslation()

  const resetPassword = async () => {
    if (isEmpty(email)) {
      return toast.error(errorMessage("auth:::009"))
    }

    if (!validateEmail(email)) {
      setRedEmail(true)
      return toast.error(errorMessage("auth:::011"))
    }

    setSubmitting(true)
    try {
      const data = {
        email: email.trim().toLowerCase(),
        userType,
      }
      const resp = await axios.post(`${API_URL}/auth/applyReset`, data)

      const { message, status } = resp.data

      if (status === "failed") {
        setSubmitting(false)
        return messageToToasts(message)
      }
      setEmailSent(true)
    } catch (error) {
      errorMessage("sw:::000")
    }
    setSubmitting(false)
  }
  return (
    <div className="columns is-centered">
      <div className="column is-5">
        <div className="box">
          {emailSent ? (
            <div className="notification has-text-left">
              <div className="content">{t("resetPassword.applyMessage")}</div>
            </div>
          ) : (
            <form>
              <div className="field">
                <label className="label has-text-left">{t("auth.email")}</label>
                <div className="control has-icons-left">
                  <input
                    className={`input ${redEmail ? "is-danger" : ""}`}
                    type="email"
                    value={email}
                    placeholder=""
                    onChange={e => setEmail(e.target.value)}
                    onFocus={() => setRedEmail(false)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-envelope"></i>
                  </span>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <div
                    className={`button ${
                      submitting ? "is-loading" : ""
                    } is-dark`}
                    type="submit"
                    onClick={() => resetPassword()}
                  >
                    {t("resetPassword.resetSubmit")}
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
        <hr />
        <LangAuthSelector />
      </div>
    </div>
  )
}

export default ApplyReset
