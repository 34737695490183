import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { ScenariosContext } from "../../../context/ScenariosContext"
import { UserContext } from "../../../context/UserContext"


const ScenarioSearch = () => {
  const { setRequest } = useContext(ScenariosContext)
  const { t } = useTranslation()
  const {
    author,
    setAuthor,
    visibility,
    setVisibility,
    newFirst,
    setNewFirst,
  } = useContext(ScenariosContext)

  const { user: curUser } = useContext(UserContext)
  return (
    <>
      <div className="box">

        <div className="columns is-centered">
          <div className="column is-10">
            <div className="form">
              <label className="label has-text-centered">
                {t("scenarios.search")}
              </label>
              <div className="field has-addons">
                <p className="control is-expanded">
                  <input
                    type="text"
                    className="input is-small is-link"
                    placeholder={t("scenarios.name")}
                    onChange={e => setRequest(e.target.value.trim())}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="buttons has-addons is-right">
              <button
                className={`button is-small ${visibility === "all" ? "is-success is-selected" : ""
                  }`}
                onClick={() => setVisibility("all")}
              >
                {t("scenarios.all")}
              </button>
              <button
                className={`button is-small ${visibility === "private" ? "is-success is-selected" : ""
                  }`}
                onClick={() => {
                  setVisibility("private")
                  setAuthor("self")
                }}
              >
                {t("scenarios.private")}
              </button>
              <button
                className={`button is-small ${visibility === "public" ? "is-success is-selected" : ""
                  }`}
                onClick={() => setVisibility("public")}
              >
                {t("scenarios.public")}
              </button>
            </div>
          </div>
          <div className="column">
            <div className="buttons has-addons is-centered">
              <button
                className={`button is-small ${newFirst ? "is-success is-selected" : ""
                  }`}
                onClick={() => setNewFirst(true)}
              >
                {t("scenarios.newBefore")}
              </button>
              <button
                className={`button is-small ${!newFirst ? "is-success is-selected" : ""
                  }`}
                onClick={() => setNewFirst(false)}
              >
                {t("scenarios.oldBefore")}
              </button>
            </div>
          </div>
          <div className="column">
            <div className="buttons has-addons is-left">
              <button
                className={`button is-small ${author === "all" ? "is-success is-selected" : ""
                  }`}
                onClick={() => setAuthor("all")}
                disabled={
                  visibility === "private" && curUser.userType !== "admin"
                    ? "disabled"
                    : ""
                }
              >
                {t("scenarios.all")}
              </button>
              <button
                className={`button is-small ${author === "self" ? "is-success is-selected" : ""
                  }`}
                onClick={() => setAuthor("self")}
              >
                {t("scenarios.its")}
              </button>
              <button
                className={`button is-small ${author === "other" ? "is-success is-selected" : ""
                  }`}
                onClick={() => setAuthor("other")}
                disabled={
                  visibility === "private" && curUser.userType !== "admin"
                    ? "disabled"
                    : ""
                }
              >
                {t("scenarios.foreign")}
              </button>
            </div>
          </div>
        </div>


      </div>
    </>
  )
}

export default ScenarioSearch
