import { useTranslation } from "react-i18next"

const PasswordRules = () => {
  const { t } = useTranslation()
  return (
    <div className="notification has-text-left">
      <div className="content">
        {t("pwdRules.mustContain")}
        <ul>
          <li>
            {t("pwdRules.notLess")}{" "}
            <strong>{t("pwdRules.eightSymbols")}</strong>
          </li>
          <li>
            {t("pwdRules.atLeast")}{" "}
            <strong>{t("pwdRules.uppercaseLetter")}</strong>
          </li>
          <li>
            {t("pwdRules.atLeast")}{" "}
            <strong>{t("pwdRules.lowercaseLetter")}</strong>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default PasswordRules
