import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { ScenariosContext } from "../../../context/ScenariosContext"
import useUsers from "../../../context/useUsers"
import LoadingSpinner from "../../../helpers/LoadingSpinner"
import Pagination from "../../../helpers/Pagination"
import Scenario from "./Scenario"

const ScenarioList = () => {
  const {
    matches,
    matchesLoading,
    pageCount,
    currentPage,
    setCurrentPage,
  } = useContext(ScenariosContext)
  const { loading: usersLoading, users } = useUsers("all")
  const { t } = useTranslation()
  return (
    <>
      {usersLoading || matchesLoading ? (
        <LoadingSpinner />
      ) : Object.keys(matches).length ? (
        <>

          <Pagination
            pageCount={pageCount}
            page={currentPage}
            setPage={setCurrentPage}
          />

          <br />
          {Object.keys(matches).map(id => (
            <Scenario
              key={id}
              scenario={matches?.[id]}
              user={users?.[matches[id]?.authorID]}
            />
          ))}

        </>
      ) : (
        <div className=" title is-4 has-text-grey has-text-centered">
          {t("listEmpty")}
        </div>
      )}
    </>
  )
}

export default ScenarioList
