import { useLocation } from "react-router"
import { toast } from "react-toastify"
import { errorMessage } from "./errors"


export const messageToToasts = message => {
  const arrayMessages = errorMessage(message).split("\n")

  if (arrayMessages.length === 1) return toast.error(arrayMessages[0])
  else
    return arrayMessages.slice(0, -1).forEach(mes => {
      toast.error(mes)
    })
}

export const API_URL = process.env.REACT_APP_API_URL
// export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY
// export const API_URL = "http://localhost:3070"

export const isStrongPwd = password => {
  var regExp = /(?=.*\d)(?=.*[a-zа-яё])(?=.*[A-ZА-ЯЁ]).{8,}/

  var validPassword = regExp.test(password)

  return validPassword
}

export const isEmpty = field => {
  var regExp = /\S/
  var validField = regExp.test(field)

  return !validField
}

export const isEmptyEditor = state => {
  return !state.getCurrentContent().hasText()
}
export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const userTypesOptions = [
  { label: "Ordinary", value: "ordinary" },
  { label: "Admin", value: "admin" },
]

export const inviteStatusOptions = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
]

export const statusColorOprions = {
  awaiting: "is-warning",
  running: "is-link",
  failed: "is-danger",
  finished: "is-success",
}

export const statusOptions = {
  awaiting: "Awaiting",
  running: "Running",
  failed: "Error",
  finished: "Finished",
}

export const coloringOptions = {
  ru: [
    { label: "Нет", value: "no" },
    { label: "Базовая", value: "basic" },
    { label: "Сравнение", value: "comparison" },
  ],
  en: [
    { label: "No", value: "no" },
    { label: "Basic", value: "basic" },
    { label: "Comparison", value: "comparison" },
  ]

}

export const outputFormatOptions = {
  ru: [
    { label: "Стандартный", value: "default" },
    { label: "Комбинированный", value: "combined" },
    { label: "Сравнение", value: "comparison" },
  ],
  en: [
    { label: "Standard", value: "default" },
    { label: "Combined", value: "combined" },
    { label: "Comparison", value: "comparison" },
  ]
}



export function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const copyToClipboard = async text => {
  await navigator.clipboard.writeText(text);
}

export const objectIDLength = 24

export const cutString = (string, length) => {
  if (string.length > length) {
    return string.slice(0, length) + "..."
  }
  return string
}

export const modelOptions = {
  ru: [
    { label: "Стандартная", value: "standard" },
    { label: "C ценами отсечения", value: "with_cutoff" },
    { label: "C минимальными маржами", value: "with_min_margins" },
  ],
  en: [
    { label: "Standard", value: "standard" },
    { label: "With cutoff prices", value: "with_cutoff" },
    { label: "With minimum margins", value: "with_min_margins" },

  ]
}

export const modelTypeOptions = {
  ru: [
    { label: "Статическая", value: "static" },
    { label: "Динамическая", value: "dynamic" },
  ],
  en: [
    { label: "Static", value: "static" },
    { label: "Dynamic", value: "dynamic" },
  ]
}