import { useTranslation } from "react-i18next"
import { Switch, Link, Redirect, Route, useRouteMatch } from "react-router-dom"
import InviteListView from "./InviteListView"
import InviteNewForm from "./InviteNewForm"

const InviteView = () => {
  const { path, url } = useRouteMatch()
  const { t } = useTranslation()

  const menu = (
    <div className="box">
      <aside className="menu">
        <ul className="menu-list">
          <li>
            <Link to={url + "/add"}>{t("invite.create")}</Link>
          </li>
          <li>
            <Link to={url + "/list"}>{t("invite.list")}</Link>
          </li>
        </ul>
      </aside>
    </div>
  )

  return (
    <>
      <div className="columns">
        <div className="column is-3">{menu}</div>
        <div className="column is-9">
          <Switch>
            <Route exact path={path}>
              <Redirect to={url + "/list"} />
            </Route>
            <Route path={path + "/list"}>
              <InviteListView />
            </Route>
            <Route path={path + "/add"}>
              <InviteNewForm />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default InviteView
