import { useContext, useState } from "react"
import { UserContext } from "../context/UserContext"
import { Link, useHistory } from "react-router-dom"
import { ReactComponent as Logo } from "../logo.svg"
import ruFlag from "./_ruFlag.png"
import enFlag from "./_enFlag.png"
import { useTranslation } from "react-i18next"

const NavBar = ({ children }) => {
  const { t, i18n } = useTranslation()
  const { logout, isSigned } = useContext(UserContext)
  const history = useHistory()

  const [showBurger, setShowBurger] = useState(false)

  const changeLng = () => {
    if (i18n.language === "ru") i18n.changeLanguage("en")
    else i18n.changeLanguage("ru")
  }

  const lngImg = (
    <img
      src={i18n.language === "ru" ? ruFlag : enFlag}
      alt={"current language: " + i18n.language}
    />
  )

  const signOut = () => {
    logout()
    history.push("/")
  }

  return (
    <>
      <nav className="navbar is-dark">
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to={"/"} className="navbar-item">
              <Logo height="55" />
            </Link>
          </div>
          <span
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="appNavbar"
            onClick={() => setShowBurger(!showBurger)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </span>
        </div>
        <div className={"navbar-menu" + (showBurger ? " is-active" : "")}>
          <div className="navbar-start"> {children} </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <button className="button is-light" onClick={changeLng}>
                  {lngImg}
                </button>
              </div>
            </div>
            {isSigned ? (
              <div className="navbar-item">
                <div className="buttons">
                  <div className="button is-white" onClick={signOut}>
                    {t("navbar.logout")}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="navbar-item">
                  <div className="buttons">
                    <Link className="button is-white" to="/auth">
                      {t("navbar.login")}
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  )
}

export default NavBar
