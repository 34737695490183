import { Switch, Route, useRouteMatch } from "react-router-dom"
import Login from "./Login"

const Auth = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <div className="section">
        <div className="container has-text-centered">
          <Switch>
            <Route path={path + "/admin"}>
              <Login userType="admin" />
            </Route>

            <Route path={path}>
              <Login userType="ordinary" />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default Auth
