// Text field with buttons to save update message
//

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, isEmptyEditor, messageToToasts } from "../../../utils"
import { errorMessage } from "../../../errors"
import axios from "axios"

import { convertToRaw, EditorState, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import draftToHtml from 'draftjs-to-html';

const defaultContentState = EditorState.createWithContent(
    ContentState.createFromBlockArray(
        convertFromHTML('')
    )
)

const UpdateMessageCreate = () => {

    const { t } = useTranslation()

    const [textRu, setTextRu] = useState(defaultContentState)
    const [textEn, setTextEn] = useState(defaultContentState)

    const [redTextRu, setRedTextRu] = useState(false)
    const [redTextEn, setRedTextEn] = useState(false)

    const [redChangeLng, setRedChangeLng] = useState(false)


    const [editorLang, setEditorLang] = useState("ru")

    const [submittingCreate, setSubmittingCreate] = useState(false)
    const [submittingCreateWithMail, setSubmittingCreateWithMail] = useState(false)

    const [sureMail, setSureMail] = useState(false)


    const createMessage = async (e, withMail) => {
        e.preventDefault()

        if (isEmptyEditor(textRu)) {
            setRedTextRu(true)
            if (editorLang === "en") setRedChangeLng(true)
            return toast.error(t("main.updateMessageCreate.ruError"))
        }

        if (isEmptyEditor(textEn)) {
            setRedTextEn(true)
            if (editorLang === "ru") setRedChangeLng(true)
            return toast.error(t("main.updateMessageCreate.enError"))
        }

        withMail ? setSubmittingCreateWithMail(true) : setSubmittingCreate(true)

        try {

            const data = {
                locales: {
                    ru: draftToHtml(convertToRaw(textRu.getCurrentContent())),
                    en: draftToHtml(convertToRaw(textEn.getCurrentContent())),
                }
            }

            const resp = await axios.post(
                `${API_URL}/messages/update?withMail=${withMail}`,
                data
            )

            const { status, message } = resp.data

            if (status === "failed") {
                return messageToToasts(message)
            }


            setTextRu(defaultContentState)
            setTextEn(defaultContentState)
            setSubmittingCreate(false)
            setSubmittingCreateWithMail(false)
            setSureMail(false)
            toast.success(t("main.updateMessageCreate.success"))
        } catch (error) {

            setSubmittingCreate(false)
            setSubmittingCreateWithMail(false)
            setSureMail(false)
            toast.error(errorMessage("sw:::000"))
        }

    }


    return (

        <div className="box">

            {/* <ReCaptcha /> */}

            <div className="columns is-vcentered">
                <div className="column"></div>
                <div className="column is-three-quarters">
                    <div className="title is-4 has-text-centered">{t("main.updateMessageCreate.title")}</div>

                    <div className="field">
                        <div className="control">
                            <Editor
                                editorState={editorLang === "en" ? textEn : textRu}
                                editorStyle={{
                                    border: `1px solid ${(editorLang === "ru" && redTextRu) || (editorLang === "en" && redTextEn) ? "red" : "grey"}`
                                    ,
                                    height: '200px'
                                }}
                                onFocus={() => {
                                    if (editorLang === "ru") setRedTextRu(false)
                                    if (editorLang === "en") setRedTextEn(false)
                                }}
                                onEditorStateChange={e => {
                                    editorLang === "en" ? setTextEn(e) : setTextRu(e)
                                }}
                            />
                        </div>
                    </div>




                    {sureMail ? (

                        <div className="columns is-centered">
                            <div className="column is-one-quarter">
                                <button
                                    className={`button is-small is-fullwidth is-link ${submittingCreateWithMail ? "is-loading" : ""
                                        }`}
                                    onClick={e => createMessage(e, true)}
                                    disabled={submittingCreate}
                                >
                                    {t("main.updateMessage.sureSend.yes")}
                                </button>
                            </div>
                            <div className="column is-one-quarter">
                                <button
                                    className={`button is-small is-fullwidth is-danger`}
                                    onClick={() => setSureMail(false)}
                                    disabled={submittingCreateWithMail}
                                >
                                    {t("main.updateMessage.sureSend.no")}
                                </button>
                            </div>
                        </div>


                    ) : (
                        <div className="columns is-fullwidth">
                            <div className="column is-half">
                                <button
                                    className={`button is-small is-fullwidth is-half is-link ${submittingCreate ? "is-loading" : ""
                                        }`}
                                    onClick={e => createMessage(e, false)}
                                    disabled={submittingCreate}
                                >
                                    {t("main.updateMessage.create")}
                                </button>
                            </div>
                            <div className="column is-half">
                                <button
                                    className={`button is-small is-fullwidth is-success`}
                                    onClick={() => setSureMail(true)}
                                    disabled={submittingCreateWithMail}
                                >
                                    {t("main.updateMessage.createWithMail")}
                                </button>
                            </div>
                        </div>
                    )}

                </div>
                <div className="column">


                    <div className={`button ${redChangeLng ? "is-danger" : ""}`}

                        onClick={
                            () => {
                                setRedChangeLng(false)
                                setEditorLang(editorLang === "en" ? "ru" : "en")
                            }
                        }>
                        {editorLang === "en" ? "English" : "Русский"}
                    </div>
                </div>
            </div>

        </div >
    )
}

export default UpdateMessageCreate