import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import App from "./App"

import "react-toastify/dist/ReactToastify.min.css"
import "font-awesome/css/font-awesome.min.css"

import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import UserContextProvider from "./context/UserContext"
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// import { RECAPTCHA_KEY } from "./utils"
ReactDOM.render(
  <React.StrictMode>
    {/* <GoogleReCaptchaProvider
      reCaptchaKey={process.env.RECAPTCHA_KEY}
      scriptProps={{ async: true }}
    > */}

    <UserContextProvider>
      <BrowserRouter
        getUserConfirmation={(message, callback) => {
          const allowTransition = window.confirm(message)
          window.setTimeout(() => {
            callback(allowTransition)
          }, 1000)
        }}
      >
        <App />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
      </BrowserRouter>
    </UserContextProvider>
    {/* </GoogleReCaptchaProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
)
