import { useTranslation } from "react-i18next"

const Pagination = ({ page, pageCount, setPage }) => {
  const { t } = useTranslation()
  return (
    <div className="">
      <div className="columns">
        <div className="column is-4 has-text-left">
          <button
            className="button"
            disabled={page <= 1}
            onClick={() => setPage(page - 1)}
          >
            {t("previousPage")}
          </button>
        </div>

        <div className="column is-4 has-text-centered">
          <button className="button" disabled>
            {t("page")} {page}
          </button>
        </div>

        <div className="column is-4 has-text-right">
          <button
            className="button"
            disabled={page >= pageCount}
            onClick={() => setPage(page + 1)}
          >
            {t("nextPage")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Pagination
