import InviteList from "./InviteList"
import { useState } from "react"
import { userTypesOptions } from "../../../utils"
import { inviteStatusOptions } from "../../../utils"
import Selector from "../../../helpers/Selector"

const InviteListView = () => {
  const [userType, setUserType] = useState("ordinary")
  const [isActive, setIsActive] = useState(true)

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-12">
          <div className="columns is-centered">
            <div className="column is-4">
              <Selector
                options={userTypesOptions}
                defaultValue={userTypesOptions[0]}
                onChange={target => setUserType(target.value)}
              />
            </div>
            <div className="column is-4">
              <Selector
                options={inviteStatusOptions}
                defaultValue={inviteStatusOptions[0]}
                onChange={target => setIsActive(target.value)}
              />
            </div>
          </div>
          <InviteList userType={userType} isActive={isActive} />
        </div>
      </div>
    </>
  )
}

export default InviteListView
