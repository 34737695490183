import _ from "lodash"
import Select from "react-select"
const Selector = ({
  options,
  defaultValue,
  onChange,
  onFocus,
  onClick,
  value,
  label,
  key,
  isOptionDisabled,
  isDisabled = false,
  isLoading = false,
  isRed = false,
  isSmall = false,
  placeholder = "",
  styles = {},
  isSearchable = false,
  isClearable = false,
  isMulti = false,
}) => {
  const defaultStyles = {
    control: styles => ({
      ...styles,
      border: isRed ? "1px solid red" : "",
      controlHeight: 30,
      minHeight: isSmall ? 30 : 38,
      fontSize: isSmall ? 12 : 14,

    }),
    dropdownIndicator: (base) => {
      return isSmall ? {
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
      } : base
    },
    clearIndicator: (base) => {
      return isSmall ? {
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
      } : base
    },
    option: (styles, { isFocused, isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "grey" : null,
        color: isFocused ? "white" : isDisabled ? "grey" : "black",
        fontSize: isSmall ? 12 : 14,


      }
    },

  }

  _.extend(defaultStyles, styles)

  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      onFocus={onFocus}
      onClick={onClick}
      value={value}
      label={label}
      key={key}
      isOptionDisabled={isOptionDisabled}
      styles={defaultStyles}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isLoading={isLoading}
      isDisabled={isDisabled}
      className={`${isMulti ? "basic-multi-select" : ""}`}
      isMulti={isMulti ? "isMulti" : ""}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "grey",
          primary: "grey",
          primary50: "grey",
        },
      })}
    />
  )
}

export default Selector
