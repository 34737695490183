import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import LoadingSpinner from "../helpers/LoadingSpinner"
import {
  API_URL,
  isStrongPwd,
  messageToToasts,
  isEmpty,
  useQuery,
} from "../utils"
import PasswordRules from "./PasswordRules"

const SignUp = ({ userType }) => {
  const query = useQuery()
  const history = useHistory()

  const code = query.get("code")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordRepeat, setPasswordRepeat] = useState("")
  const [codeValid, setCodeValid] = useState(null)
  const [submittingCheck, setSubmittingCheck] = useState(false)
  const [submittingForm, setSubmittingForm] = useState(false)
  const [surname, setSurname] = useState("")
  const [name, setName] = useState("")
  const [secondName, setSecondName] = useState("")

  const [redEmail, setRedEmail] = useState(false)
  const [redPassword, setRedPassword] = useState(false)
  const [redPasswordRepeat, setRedPasswordRepeat] = useState(false)
  const [redSurname, setRedSurname] = useState(false)
  const [redName, setRedName] = useState(false)

  const { t } = useTranslation()

  const signUp = async event => {
    event.preventDefault()

    if (isEmpty(surname)) {
      setRedSurname(true)
      return toast.error(errorMessage("su:::004"))
    }

    if (isEmpty(name)) {
      setRedName(true)
      return toast.error(errorMessage("su:::005"))
    }

    if (password === "") {
      setRedPassword(true)
      return toast.error(errorMessage("su:::000"))
    }

    if (passwordRepeat === "") {
      setRedPasswordRepeat(true)
      return toast.error(errorMessage("su:::001"))
    }

    if (!isStrongPwd(password)) {
      setRedPassword(true)
      setRedPasswordRepeat(true)

      return toast.error(errorMessage("su:::002"))
    }

    if (password !== passwordRepeat) {
      setRedPassword(true)
      setRedPasswordRepeat(true)
      return toast.error(errorMessage("su:::003"))
    }

    const fullName =
      `${surname.trim()} ${name.trim()} ${secondName.trim()}`.trim()

    setSubmittingForm(true)

    const data = {
      fullName,
      email,
      password,
      passwordRepeat,
      userType,
      code,
      usedInvite: code,
    }

    try {
      const resp = await axios.post(`${API_URL}/auth/signUp`, data)

      console.log(resp.data)

      const { message, status } = resp.data

      if (status === "failed") {
        setSubmittingForm(false)
        return messageToToasts(message)
      }

      setPassword("")
      setPasswordRepeat("")
      setSubmittingForm(false)
    } catch (error) {
      toast.error(errorMessage("sw:::000"))
    }

    switch (userType) {
      case "admin":
        history.push(`/auth/admin`)
        break
      default:
        history.push(`/auth`)
    }

    return toast.success(t("signUp.success"))
  }

  const checkCode = async () => {
    setSubmittingCheck(true)

    const stringUserType = String(userType)

    const data = {
      code,
      userType: stringUserType,
    }

    const resp = await axios.post(`${API_URL}/invite/check`, data)
    const { status, email } = resp.data

    console.log(resp.data)
    if (status === "failed") {
      setSubmittingCheck(false)
      setCodeValid(false)
      return
    }

    setEmail(email)
    setCodeValid(true)
    setSubmittingCheck(false)
  }

  useEffect(() => {
    checkCode()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-4">
          {submittingCheck || codeValid === null ? (
            <LoadingSpinner />
          ) : !codeValid ? (
            <div className="box">
              <h2>{t("signUp.outdated")}</h2>
            </div>
          ) : (
            <form className="box">
              <div className="field">
                <label className="label has-text-left">
                  {t("signUp.lastName")}
                </label>
                <div className="control">
                  <input
                    className={`input ${redSurname ? "is-danger" : ""}`}
                    type="text"
                    value={surname}
                    placeholder="Иванов"
                    onChange={e => setSurname(e.target.value)}
                    onFocus={() => setRedSurname(false)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label has-text-left">
                  {t("signUp.firstName")}
                </label>
                <div className="control">
                  <input
                    className={`input ${redName ? "is-danger" : ""}`}
                    type="text"
                    value={name}
                    placeholder="Иван"
                    onChange={e => setName(e.target.value)}
                    onFocus={() => setRedName(false)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label has-text-left">
                  {t("signUp.middleName")}
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    value={secondName}
                    placeholder="Иванович"
                    onChange={e => setSecondName(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-left">{t("auth.email")}</label>
                <div className="control has-icons-left">
                  <input
                    className={`input ${redEmail ? "is-danger" : ""}`}
                    type="email"
                    value={email}
                    placeholder=""
                    disabled="disabled"
                    onChange={e => setEmail(e.target.value)}
                    onFocus={() => setRedEmail(false)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-envelope"></i>
                  </span>
                </div>
              </div>

              <div className="field">
                <label className="label has-text-left">
                  {t("signUp.password")}
                </label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    className={`input ${redPassword ? "is-danger" : ""}`}
                    type="password"
                    value={password}
                    placeholder="********"
                    disabled={!codeValid ? "disabled" : ""}
                    onChange={e => setPassword(e.target.value)}
                    onFocus={e => setRedPassword(false)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-lock"></i>
                  </span>
                </div>
              </div>

              <div className="field">
                <label className="label has-text-left">
                  {t("signUp.passwordRep")}
                </label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    className={`input ${redPasswordRepeat ? "is-danger" : ""}`}
                    type="password"
                    value={passwordRepeat}
                    placeholder="********"
                    required
                    onChange={e => setPasswordRepeat(e.target.value)}
                    onFocus={e => setRedPasswordRepeat(false)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-lock"></i>
                  </span>
                </div>
              </div>

              <PasswordRules />

              <div className="field">
                <div className="control">
                  <div
                    className={`button ${submittingForm ? "is-loading" : ""
                      } is-dark`}
                    type="submit"
                    onClick={e => signUp(e)}
                  >
                    {t("signUp.signUp")}
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  )
}

export default SignUp
