import useUsers from "../../../context/useUsers"
import dayjs from "dayjs"
import LoadingSpinner from "../../../helpers/LoadingSpinner"
import { useTranslation } from "react-i18next"

const UserList = ({ userType }) => {
  const { loading, users } = useUsers(userType)
  const { t } = useTranslation()
  const usersTable = (
    <div className="box">
      {Object.keys(users).length ? (
        <div className="table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>{t("users.user")}</th>
                <th>{t("auth.email")}</th>
                <th>{t("users.created")}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(users).map(id => (
                <tr key={users[id].id}>
                  <td>{users[id].fullName}</td>
                  <th>{users[id].email}</th>
                  <td>
                    {dayjs(users[id].creationDate).format("DD/MM/YYYY - HH:mm")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="has-text-centered has-text-grey"> {t("listEmpty")} </p>
      )}
    </div>
  )

  return loading ? <LoadingSpinner /> : usersTable
}

export default UserList
