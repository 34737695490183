import { Switch } from "react-router-dom"
import ScenariosContextProvider from "../context/ScenariosContext"
import NavBar from "../helpers/NavBar"
import NavbarSections from "./NavbarSections"

import { Redirect, Route, useRouteMatch } from "react-router"

import RestrictedRoutes from "./RestrictedRoutes"
import ScenariosView from "./views/scenarios/ScenariosView"
import MainView from "./views/main/MainView"
import MessagesContextProvider from "../context/MessagesContext"

const CommonWorkspace = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <NavBar>
        <NavbarSections />
      </NavBar>

      <div className="section has-background-light">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-10">
              <Switch>
                <Route exact path={path}>
                  <Redirect to={path + "/main"} />
                </Route>
                <Route path={path + "/main"}>
                  <MessagesContextProvider>
                    <MainView />
                  </MessagesContextProvider>
                </Route>
                <Route path={path + "/scenarios"}>
                  <ScenariosContextProvider>
                    <div className="cotainer is-desktop">
                      <div className="columns is-centered">
                        <div className="column is-fullwidth">
                          <ScenariosView />
                        </div>
                      </div>
                    </div>
                  </ScenariosContextProvider>
                </Route>
                <RestrictedRoutes />
              </Switch>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default CommonWorkspace
