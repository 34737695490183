import axios from "axios"
import { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { API_URL, isStrongPwd, messageToToasts, useQuery } from "../utils"
import LoadingSpinner from "../helpers/LoadingSpinner"
import { errorMessage } from "../errors"
import { useTranslation } from "react-i18next"
import PasswordRules from "./PasswordRules"
import LangAuthSelector from "./LangAuthSelector"

const ResetPassword = () => {
  const query = useQuery()
  const history = useHistory()

  const [password, setPassword] = useState("")
  const [passwordRepeat, setPasswordRepeat] = useState("")
  const [apply, setApply] = useState({})

  const [redPassword, setRedPassword] = useState(false)
  const [redPasswordRepeat, setRedPasswordRepeat] = useState(false)

  const [submitting, setSubmitting] = useState(false)

  const [loading, setLoading] = useState(true)
  const [codeValid, setCodeValid] = useState(false)
  const { t } = useTranslation()

  const checkReset = async () => {
    const resp = await axios.get(
      `${API_URL}/auth/checkReset?code=${query.get("code")}`
    )
    const { status, apply } = resp.data

    if (status === "failed") {
      setCodeValid(false)
      setLoading(false)
      return
    }
    setApply(apply)
    setCodeValid(true)
    setLoading(false)
  }

  useEffect(
    () => checkReset(),
    // eslint-disable-next-line
    []
  )

  const resetPassword = async () => {
    if (password === "") {
      setRedPassword(true)
      return toast.error(errorMessage("su:::000"))
    }

    if (passwordRepeat === "") {
      setRedPasswordRepeat(true)
      return toast.error(errorMessage("su:::001"))
    }

    if (!isStrongPwd(password)) {
      setRedPassword(true)
      setRedPasswordRepeat(true)

      return toast.error(errorMessage("su:::002"))
    }

    if (password !== passwordRepeat) {
      setRedPassword(true)
      setRedPasswordRepeat(true)
      return toast.error(errorMessage("su:::003"))
    }

    setSubmitting(true)
    try {
      const data = {
        id: apply.userID,
        userType: apply.userType,
        newPassword: password,
        code: query.get("code"),
      }

      const resp = await axios.post(`${API_URL}/auth/resetPassword`, data)

      const { message, status } = resp.data

      if (status === "failed") {
        setSubmitting(false)
        return messageToToasts(message)
      }
    } catch (error) {
      toast.error(errorMessage("sw:::000"))
    }
    setSubmitting(false)
    switch (apply.userType) {
      case "admin":
        history.push(`/auth/admin`)
        break
      default:
        history.push(`/auth`)
    }
    toast.success(t("resetPassword.success"))
  }

  return (
    <div className="section">
      <div className="container has-text-centered">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="columns is-centered">
            <div className="column is-5">
              {codeValid ? (
                <form className="box">
                  <div className="title is-6">
                    {t("resetPassword.insertNew")}
                  </div>
                  <div className="field">
                    <label className="label has-text-left">
                      {t("signUp.password")}
                    </label>
                    <div className="control has-icons-left has-icons-right">
                      <input
                        className={`input ${redPassword ? "is-danger" : ""}`}
                        type="password"
                        value={password}
                        placeholder="********"
                        onChange={e => setPassword(e.target.value)}
                        onFocus={() => setRedPassword(false)}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa fa-lock"></i>
                      </span>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label has-text-left">
                      {t("signUp.passwordRep")}
                    </label>
                    <div className="control has-icons-left has-icons-right">
                      <input
                        className={`input ${
                          redPasswordRepeat ? "is-danger" : ""
                        }`}
                        type="password"
                        value={passwordRepeat}
                        placeholder="********"
                        required
                        onChange={e => setPasswordRepeat(e.target.value)}
                        onFocus={e => setRedPasswordRepeat(false)}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa fa-lock"></i>
                      </span>
                    </div>
                  </div>

                  <PasswordRules />

                  <div className="field">
                    <div className="control">
                      <div
                        className={`button ${
                          submitting ? "is-loading" : ""
                        } is-dark`}
                        type="submit"
                        onClick={() => resetPassword()}
                      >
                        {t("resetPassword.resetSubmit")}
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <div className="notification has-text-centered">
                  <div className="content">{t("resetPassword.outdated")}</div>
                </div>
              )}
              <hr />
              <LangAuthSelector />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ResetPassword
