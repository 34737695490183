import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ScenariosContext } from "../../../context/ScenariosContext"
import { UserContext } from "../../../context/UserContext"
import { errorMessage } from "../../../errors"
import { API_URL, cutString, isEmpty, messageToToasts, objectIDLength, coloringOptions, outputFormatOptions, modelOptions, modelTypeOptions } from "../../../utils"
import Selector from "../../../helpers/Selector"
import i18n from "../../../i18n"
import fileDownload from "js-file-download"


const ScenarioNewForm = () => {
  const [name, setName] = useState("")
  const [comparisonID, setComparisonID] = useState("")

  const [isPublic, setIsPublic] = useState(false)
  const [file, setFile] = useState({ name: "" })
  const [keyFI, setKeyFI] = useState(Math.random().toString())


  const [submittingSave, setSubmittingSave] = useState(false)
  const [submittingSaveAdd, setSubmittingSaveAdd] = useState(false)
  const [redFile, setRedFile] = useState(false)
  const [redName, setRedName] = useState(false)
  const [redComparisonID, setRedComparisonID] = useState(false)

  const [coloring, setColoring] = useState()
  const [outputFormat, setOutputFormat] = useState(null)
  const [model, setModel] = useState(null)
  const [modelType, setModelType] = useState(null)

  const [keyColoring, setKeyColoring] = useState(Math.random().toString())
  const [keyOutputFormat, setKeyOutputFormat] = useState(Math.random().toString())
  const [keyModel, setKeyModel] = useState(Math.random().toString())
  const [keyModelType, setKeyModelType] = useState(Math.random().toString())



  const { user } = useContext(UserContext)
  const { setNeedRefetchMatches } = useContext(ScenariosContext)

  useEffect(() => {
    setColoring(coloringOptions?.[i18n?.language]?.[0])
    setKeyColoring(Math.random().toString());

    setOutputFormat(outputFormatOptions?.[i18n?.language]?.[0])
    setKeyOutputFormat(Math.random().toString());

    setModel(modelOptions?.[i18n?.language]?.[0])
    setKeyModel(Math.random().toString());

    setModelType(modelTypeOptions?.[i18n?.language]?.[0])
    setKeyModel(Math.random().toString());

  }, []);
  useEffect(() => {

  }, []);
  useEffect(() => {

  }, []);


  const { t } = useTranslation()

  const saveModel = async addTask => {

    addTask ? setSubmittingSaveAdd(true) : setSubmittingSave(true)
    if (isEmpty(name)) {
      addTask ? setSubmittingSaveAdd(false) : setSubmittingSave(false)
      setRedName(true)
      return toast.error(errorMessage("sc:::002"))
    }

    if (isEmpty(file?.name) || !file?.name?.endsWith(".xlsx")) {
      addTask ? setSubmittingSaveAdd(false) : setSubmittingSave(false)
      setRedFile(true)

      return toast.error(errorMessage("sc:::003"))
    }

    if (comparisonID?.length !== objectIDLength && isEmpty(comparisonID) && outputFormat?.value === "comparison") {
      addTask ? setSubmittingSaveAdd(false) : setSubmittingSave(false)
      setRedComparisonID(true)

      return toast.error(errorMessage("sc:::004"))

    }

    try {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("name", name.trim())
      formData.append("stringAuthorID", user.userID)
      formData.append("visibility", isPublic ? "public" : "private")
      formData.append("model", model.value)
      formData.append("modelType", modelType.value)
      formData.append("coloring", coloring.value)
      formData.append("outputFormat", outputFormat.value)

      if ((comparisonID?.length === objectIDLength) && (outputFormat?.value === "comparison")) {
        formData.append("stringComparisonID", comparisonID.trim())
      }



      const resp = await axios.post(
        `${API_URL}/scenario/create?addTask=${addTask}`,
        formData
      )
      const { status, message } = resp.data

      if (status === "failed") {
        addTask ? setSubmittingSaveAdd(false) : setSubmittingSave(false)
        return messageToToasts(message)
      }

      addTask ? setSubmittingSaveAdd(false) : setSubmittingSave(false)
      setName("")
      setFile({ name: "" })
      setNeedRefetchMatches(true)
      setKeyFI(Math.random().toString())
      setComparisonID("")

      addTask
        ? toast.success(t("scenarios.successRun"))
        : toast.success(t("scenarios.success"))
    } catch (error) {
      toast.error(errorMessage("sw:::000"))
    }
    addTask ? setSubmittingSaveAdd(false) : setSubmittingSave(false)
  }

  const getExample = async () => {
    try {
      const resp = await axios.get(
        API_URL +
        `/scenario/getExample?model=${model?.value}&modelType=${modelType.value}`,
        {
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        }
      )


      fileDownload(
        resp.data,
        `${model?.value}.xlsx`
      )


    } catch (error) {
      toast.error(errorMessage("sw:::000"))
    }
  }


  // Disabling coloring options

  return (
    <>
      <div className="box is-fullwidth">


        <div className="columns">
          <div className="column">

            <div className="columns">
              <div className="column is-narrow">
                <div className="buttons has-addons is-left">
                  <button
                    className={`button is-small ${!isPublic ? "is-success is-selected" : ""
                      }`}
                    onClick={() => setIsPublic(false)}
                  >
                    {t("scenarios.privateOne")}
                  </button>
                  <button
                    className={`button is-small ${isPublic ? "is-success is-selected" : ""
                      }`}
                    onClick={() => setIsPublic(true)}
                  >
                    {t("scenarios.publicOne")}
                  </button>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <div className="control">
                    <input
                      type="text"
                      className={`input is-small ${redName ? "is-danger" : "is-link"
                        }`}
                      placeholder={t("scenarios.name")}
                      value={name}
                      onChange={e => setName(e.target.value)}
                      onFocus={() => setRedName(false)}
                    />
                  </div>
                </div>
              </div>

              <div className="column is-one-quarter">
                <div className="field">
                  <div
                    className={`file  is-small ${redFile ? "is-danger" : ""}`}
                  >
                    <label className="file-label">
                      <input
                        className="file-input"
                        type="file"
                        name="file"
                        key={keyFI}

                        onChange={e => {
                          setFile(e.target.files[0])
                          if (!name.length) {
                            setName(e.target.files[0].name.split(".")[0])
                          }
                        }}
                        onFocus={() => {
                          setRedFile(false)
                        }}
                      />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fa fa-upload"></i>
                        </span>
                        <span className="file-label">{`${file?.name?.length
                          ? cutString(file?.name, 20)
                          : t("scenarios.chooseFile")
                          }`}</span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="columns">

              <div className="column is-one-fifth">
                <div className="label is-small">{t("scenarios.model")}</div>
                <Selector
                  isSmall={true}
                  options={modelOptions?.[i18n?.language]}
                  defaultValue={modelOptions?.[i18n?.language]?.[0]}
                  onChange={target => {
                    setModel(target)
                  }
                  }

                  value={model}
                  key={keyModel}
                />


              </div>

              <div className="column is-one-fifth">
                <div className="label is-small">{t("scenarios.modelType")}</div>
                <Selector
                  isSmall={true}
                  options={modelTypeOptions?.[i18n?.language]}
                  defaultValue={modelTypeOptions?.[i18n?.language]?.[0]}
                  onChange={target => {
                    setModelType(target)
                  }
                  }

                  value={modelType}
                  key={keyModelType}
                />


              </div>
              <div className="column is-one-fifth">
                <div className="label is-small">{t("scenarios.outputFormat")}</div>
                <Selector
                  isSmall={true}
                  options={outputFormatOptions?.[i18n?.language]}
                  defaultValue={outputFormatOptions?.[i18n?.language]?.[0]}
                  onChange={target => {
                    setOutputFormat(target)

                    switch (target.value) {
                      case "comparison":
                        setColoring(coloringOptions?.[i18n?.language]?.[2])
                        setKeyColoring(Math.random().toString())
                        break;

                      default:
                        if (coloring.value === "comparison") {
                          setColoring(coloringOptions?.[i18n?.language]?.[0])
                          setKeyColoring(Math.random().toString())
                        }

                    }
                  }
                  }

                  value={outputFormat}
                  key={keyOutputFormat}
                />


              </div>
              <div className="column is-one-fifth">
                <div className="label is-small">{t("scenarios.coloring")}</div>
                <Selector
                  isSmall={true}
                  options={coloringOptions?.[i18n?.language]}
                  defaultValue={coloringOptions?.[i18n?.language]?.[0]}
                  key={keyColoring}

                  isOptionDisabled={option =>
                    (outputFormat.value === "comparison" && option.value !== "comparison")
                    || option.isDisabled
                  }
                  value={coloring}

                  onChange={target => {
                    setColoring(target)

                    switch (target.value) {
                      case "comparison":
                        setOutputFormat(outputFormatOptions?.[i18n?.language]?.[2])
                        setKeyOutputFormat(Math.random().toString())
                        break;

                      default:

                    }
                  }
                  }

                  placeholder={t("scenarios.coloring")} />
              </div>

              <div className="column is-one-fifth">

                {outputFormat?.value === "comparison" &&
                  (
                    <>
                      <div className="label is-small">{t("scenarios.comparisonID")}</div>
                      <div className="field">
                        <div className="control">
                          <input
                            type="text"
                            className={`input is-small ${redComparisonID ? "is-danger" : "is-link"
                              }`}
                            value={comparisonID}
                            onChange={e => setComparisonID(e.target.value)}
                            onFocus={() => setRedComparisonID(false)}
                          />
                        </div>
                      </div>
                    </>
                  )}
              </div>

            </div>
          </div>


          <div className="column is-one-quarter">
            <div className="buttons">
              <button
                className={`button is-small is-fullwidth is-link ${submittingSave ? "is-loading" : ""
                  } `}
                onClick={() => getExample()}
              >
                {t("scenarios.getExample")}
              </button>
              <button
                className={`button is-small is-fullwidth is-success ${submittingSaveAdd ? "is-loading" : ""
                  } `}
                onClick={() => saveModel(true)}
              >
                {t("scenarios.addRun")}
              </button>
            </div>
          </div>
        </div >
      </div >
    </>
  )
}

export default ScenarioNewForm