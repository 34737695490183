import axios from "axios"
import { useEffect, useState } from "react"
import { API_URL, messageToToasts } from "../utils"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"

const useUsers = userType => {
  const [users, setUsers] = useState({})
  const [loading, setLoading] = useState(false)
  const [needRefetch, setNeedRefetch] = useState(false)

  const fetchUsers = async () => {
    try {
      const resp = await axios.get(`${API_URL}/user/list?userType=${userType}`)

      const { status, message, users: usersList } = resp.data

      if (status === "failed") {
        return messageToToasts(message)
      }

      const usersObj = {}

      for (const item of usersList) {
        usersObj[item.id] = item
      }

      setUsers(usersObj)
      setLoading(false)
    } catch (error) {
      toast.error(errorMessage("sw:::000"))
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchUsers()
    // eslint-disable-next-line
  }, [userType])

  useEffect(() => {
    if (needRefetch) {
      setLoading(true)
      fetchUsers()
    }
    // eslint-disable-next-line
  }, [needRefetch])

  return { loading, users, setNeedRefetch }
}

export default useUsers
