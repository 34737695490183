import { useContext } from "react";
import { MessagesContext } from "../../../context/MessagesContext";
import Message from "./Message";
import { useTranslation } from "react-i18next";
import Pagination from "../../../helpers/Pagination";


const UpdateMessageList = () => {
    const { updateMessages: messages, pageCount, currentPage, setCurrentPage } = useContext(MessagesContext);

    const { t } = useTranslation()
    return (


        messages.length === 0 ? null :
            <>
                <br />
                <div className="title has-text-centered">
                    {t("main.updates")}
                </div>

                <Pagination
                    pageCount={pageCount}
                    page={currentPage}
                    setPage={setCurrentPage}
                />

                <br />

                {messages.length === 0 ? null :
                    <div className="message-list">
                        {messages?.map((message) => (
                            <Message key={message.id} message={message} />
                        ))}
                    </div>}
            </>
    );
}

export default UpdateMessageList