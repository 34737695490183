import axios from "axios"
import dayjs from "dayjs"
import { toast } from "react-toastify"
import fileDownload from "js-file-download"
import {
  API_URL,
  messageToToasts,
  statusColorOprions,
  statusOptions,
  copyToClipboard,
} from "../../../utils"
import { ScenariosContext } from "../../../context/ScenariosContext"
import { useContext, useState } from "react"
import { UserContext } from "../../../context/UserContext"
import { errorMessage } from "../../../errors"
import { useTranslation } from "react-i18next"

const Scenario = ({ scenario, user }) => {
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingAdd, setLoadingAdd] = useState(false)
  const [sureQuestion, setSureQuestion] = useState(false)

  const { setNeedRefetchMatches } = useContext(ScenariosContext)
  const { user: curUser } = useContext(UserContext)

  const { t } = useTranslation()

  const isStatus = scenario?.status?.length > 0


  const status = isStatus
    ? scenario?.status?.[scenario?.status?.length - 1].status
    : ""

  const loadFile = async (type, id) => {
    try {
      const resp = await axios.get(
        API_URL +
        `/scenario/load?fileID=${id}&fileType=${type}&scenarioID=${scenario.id}`,
        {
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        }
      )


      switch (type) {
        case "log":
          fileDownload(
            resp.data,
            `${scenario.name}.log`
          )
          break
        case "comparison":
          fileDownload(
            resp.data,
            `${scenario.name}.zip`
          )
          break
        default:
          fileDownload(
            resp.data,
            `${type}_${scenario.name}.xlsx`
          )
      }
    } catch (error) {
      toast.error(errorMessage("sw:::000"))
    }
  }

  const deleteScenario = async scenarioID => {
    setLoadingDelete(true)

    try {
      setLoadingDelete(true)
      const resp = await axios.get(
        `${API_URL}/scenario/delete?scenarioID=${scenarioID}`
      )

      const { status, message } = resp.data

      if (status === "failed") {
        setLoadingDelete(false)

        return messageToToasts(message)
      }

      setNeedRefetchMatches(true)
      setLoadingDelete(false)
    } catch (error) {
      setLoadingDelete(false)

      toast.error(errorMessage("sw:::000"))
    }
  }

  const addTask = async id => {
    setLoadingAdd(true)
    try {
      const resp = await axios.post(
        `${API_URL}/scenario/addTask?scenarioID=${id}&modelType=base`
      )

      const { status, message } = resp.data

      if (status === "failed") {
        setLoadingAdd(false)

        return messageToToasts(message)
      }

      setNeedRefetchMatches(true)
      setLoadingAdd(false)
    } catch (error) {
      setLoadingAdd(false)

      toast.error(errorMessage("sw:::000"))
    }
  }

  return (
    <div className="box">
      <div className="columns ">
        <div className="column is-three-fifth">
          <div className="columns">
            <div className="column">
              <h1 className="title is-5">{scenario?.name} </h1>
            </div>
            <div className="column is-4 has-text-centered">
              <div
                className="button"
                onClick={() => copyToClipboard(scenario?.id)}
              >
                {t("scenarios.copyID")}
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              {user?.fullName},{" "}
              {dayjs(scenario.creationDate).format("DD/MM/YY - HH:mm")}
            </div>
            <div className="column is-4 has-text-centered">
              {" "}
              {isStatus ? (
                <div className={`tag is-rounded ${statusColorOprions[status]}`}>
                  {t(statusOptions[status])}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="column is-one-fifth has-text-right">
          <div className="buttons is-right">
            <button
              className="button is-fullwidth  is-small is-link"
              onClick={() => loadFile("scenario", scenario?.id)}
            >
              {t("scenarios.input")}
            </button>
            <button
              className="button is-fullwidth  is-small is-link"
              onClick={() => loadFile("log", scenario?.id)}
            >
              {t("scenarios.log")}
            </button>
          </div>
        </div>
        <div className="column is-one-fifth has-text-right">
          {sureQuestion ? (
            <>
              <div className="has-text-centered"> {t("scenarios.sure")}</div>

              <div className="columns">
                <div className="column is-half">
                  <div
                    className={`button is-danger is-small is-fullwidth  ${loadingDelete ? "is-loading" : ""
                      }`}
                    onClick={() => deleteScenario(scenario?.id)}
                  >
                    {t("scenarios.yes")}
                  </div>
                </div>
                <div className="column is-half">
                  <div
                    className="button is-link is-small is-fullwidth"
                    onClick={() => setSureQuestion(false)}
                  >
                    {t("scenarios.no")}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="buttons is-right">
              {isStatus ? (
                <button
                  className="button is-fullwidth is-small is-link"
                  disabled={status !== "finished" ? "disabled" : ""}
                  onClick={() => {
                    switch (scenario?.outputFormat) {
                      case "comparison":
                        loadFile("comparison", scenario?.id)
                        break
                      default:
                        loadFile("result", scenario?.id)
                    }
                  }}
                >
                  {t("scenarios.result")}
                </button>
              ) : (
                <button
                  className={`button is-fullwidth is-small is-link ${loadingAdd ? "is-loading" : ""
                    }`}
                  onClick={() => addTask(scenario?.id)}
                >
                  {t("scenarios.runTask")}
                </button>
              )}

              {curUser.userID === scenario.authorID ||
                curUser.userType === "admin" ? (
                <button
                  className={`button is-fullwidth is-danger is-small`}
                  onClick={() => setSureQuestion(true)}
                  disabled={
                    isStatus &&
                      status !== "finished" &&
                      status !== "failed"
                      ? "disabled"
                      : ""
                  }
                >
                  {t("scenarios.delete")}
                </button>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Scenario
