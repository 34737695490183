import ScenarioList from "./ScenarioList"
import ScenarioNewForm from "./ScenarioNewForm"
import ScenarioSearch from "./ScenarioSearch"

const ScenariosView = () => {

  return (
    <>

      <ScenarioNewForm />

      <ScenarioSearch />

      <ScenarioList />
    </>
  )
}

export default ScenariosView
