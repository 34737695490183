import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../../../errors"
import Selector from "../../../helpers/Selector"
import {
  API_URL,
  messageToToasts,
  userTypesOptions,
  validateEmail,
} from "../../../utils"

const InviteNewForm = () => {
  const [email, setEmail] = useState("")
  const [userType, setUserType] = useState("ordinary")
  const [submitting, setSubmitting] = useState(false)
  const [redEmail, setRedEmail] = useState(false)
  const { t } = useTranslation()

  const sendInvite = async (email, userType) => {
    if (email === "") {
      setRedEmail(true)
      return toast.error(errorMessage("auth:::009"))
    }

    if (!validateEmail(email)) {
      setRedEmail(true)
      return toast.error(errorMessage("auth:::011"))
    }

    setSubmitting(true)
    try {
      const data = {
        email: email.trim().toLowerCase(),
        userType,
      }
      const resp = await axios.post(`${API_URL}/invite/add`, data)

      const { status, message } = resp.data

      if (status === "failed") {
        setSubmitting(false)
        return messageToToasts(message)
      }

      setSubmitting(false)
      setEmail("")
      toast.success(t("invite.success"))
    } catch (error) {
      toast.error(errorMessage("sw:::000"))
    }
    setSubmitting(false)
  }

  return (
    <>
      <div className="box">
        <div className="columns is-centered">
          <div className="column is-4">
            <div className="field">
              <label className="label has-text-left">{t("auth.email")}</label>
              <div className="control">
                <input
                  className={`input ${redEmail ? "is-danger" : ""}`}
                  type="email"
                  value={email}
                  placeholder="somemail@gmail.com"
                  onChange={e => setEmail(e.target.value)}
                  onFocus={e => setRedEmail(false)}
                />
              </div>
            </div>
          </div>
          <div className="column is-4">
            <label className="label has-text-left">{t("users.userType")}</label>
            <Selector
              options={userTypesOptions}
              defaultValue={userTypesOptions[0]}
              onChange={target => setUserType(target.value)}
            />
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="field">
              <div className="control">
                <button
                  className={`button is-dark ${
                    submitting ? "is-loading" : ""
                  } is-fullwidth`}
                  onClick={() => sendInvite(email, userType)}
                >
                  {t("invite.create")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InviteNewForm
