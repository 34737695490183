import { useTranslation } from "react-i18next"

const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          {t("footer.techSupport")}
          <a href="mailto:dmf@statspace.io">dmf@statspace.io</a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
