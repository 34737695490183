import { createContext, useState } from "react"
import useScenarios from "./useScenarios"

export const ScenariosContext = createContext()

const ScenariosContextProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const {
    matches,
    loading: matchesLoading,
    setLoading: setMatchesLoading,
    request,
    setRequest,
    setNeedRefetch: setNeedRefetchMatches,
    author,
    setAuthor,
    visibility,
    setVisibility,
    newFirst,
    setNewFirst,
    pageCount,
  } = useScenarios(currentPage, setCurrentPage)

  const value = {
    request,
    setRequest,
    matches,
    matchesLoading,
    setMatchesLoading,
    setNeedRefetchMatches,
    author,
    setAuthor,
    visibility,
    setVisibility,
    newFirst,
    setNewFirst,
    currentPage,
    setCurrentPage,
    pageCount,
  }

  return (
    <ScenariosContext.Provider value={value}>
      {children}
    </ScenariosContext.Provider>
  )
}

export default ScenariosContextProvider
