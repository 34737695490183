import { useContext } from "react"
import i18n from "../../../i18n"
import { UserContext } from "../../../context/UserContext"
import axios from "axios"
import { API_URL, messageToToasts } from "../../../utils"
import { MessagesContext } from "../../../context/MessagesContext"
import { errorMessage } from "../../../errors"
import { toast } from "react-toastify"

const Message = ({ message }) => {

    const { locales, creationDate } = message

    const {
        user: { userType }
    } = useContext(UserContext)

    const { setNeedRefetchUpdateMessages } = useContext(MessagesContext)

    // send id to delete message
    const deleteMessage = async id => {

        try {
            const resp = await axios.delete(
                `${API_URL}/messages/update?id=${id}`
            )

            const {
                status,
                message,
            } = resp.data

            if (status === "failed") {
                return messageToToasts(message)
            }


            setNeedRefetchUpdateMessages(true)
        } catch (error) {
            console.log(error)
            toast.error(errorMessage("sw:::000"))
        }

    }



    return (

        <article className="message is-success">
            <div className="message-header">
                <p>{new Date(creationDate)
                    .toLocaleDateString("ru-RU")}</p>

                {
                    userType === "admin"
                        ? <button
                            class="delete"
                            aria-label="delete"
                            onClick={() => deleteMessage(message.id)
                            }
                        />
                        : null}
            </div>

            <div className="message-body">
                <div className="content">

                    <div dangerouslySetInnerHTML={{ __html: locales?.[i18n?.language] }} />
                </div>
            </div>
        </article>
    )
}

export default Message  