import axios from "axios"
import _ from "lodash"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL } from "../utils"

const useScenarios = (currentPage, setCurrentPage) => {
  const [author, setAuthor] = useState("self")
  const [visibility, setVisibility] = useState("private")
  const [newFirst, setNewFirst] = useState(true)
  const [loading, setLoading] = useState(false)
  const [matches, setMatches] = useState({})

  const [needRefetch, setNeedRefetch] = useState(false)
  const [request, setRequest] = useState("")
  const [pageCount, setPageCount] = useState(0)

  const fetchScenarios = async requestString => {
    setLoading(true)

    try {
      const resp = await axios.get(
        `${API_URL}/scenario/list?author=${author}&visibility=${visibility}&request=${requestString}&page=${currentPage}&newFirst=${newFirst}`
      )

      const {
        scenarios: matchesList,
        pageCount: numPages,
      } = resp.data

      const matchesObj = {}

      for (const item of matchesList) {
        matchesObj[item.id] = item
      }


      setPageCount(numPages)
      setMatches(matchesObj)
      setLoading(false)
    } catch (error) {
      toast.error(errorMessage("sw:::000"))
    }
  }

  useEffect(() => {
    if (needRefetch) {
      setCurrentPage(1)
      fetchScenarios(request)
      setNeedRefetch(false)
    }
    // eslint-disable-next-line
  }, [needRefetch])

  useEffect(() => {
    setCurrentPage(1)
    fetchScenarios(request)
    setNeedRefetch(false)
    // eslint-disable-next-line
  }, [request, visibility, author, newFirst])

  useEffect(() => {
    fetchScenarios(request)
    setNeedRefetch(false)
    // eslint-disable-next-line
  }, [currentPage])

  const debouncedSetRequest = _.debounce(setRequest, 1000)

  return {
    matches,
    loading,
    setLoading,
    request,
    setRequest: debouncedSetRequest,
    setNeedRefetch,
    author,
    setAuthor,
    visibility,
    setVisibility,
    newFirst,
    setNewFirst,
    pageCount,
  }
}

export default useScenarios
